<template>
  <div>
    <!-- 신청정보 -->
    <div class="info-container">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">신청정보</span>
        </div>
      </div>
      <!-- 계정 담당 -->
      <div class="info-content-wrap">
        <span class="content-category">계정 담당</span>
        <input-text style="width: 240px" v-model="authData.memo" />
      </div>
      <!-- 아이디 설정 -->
      <div class="info-content-wrap">
        <span class="content-category">아이디 설정</span>
        <input-text
          style="width: 240px; margin-right: 13px"
          v-model="authData.id"
          @input="
            isCheckEmail = false;
            isFirst = true;
          "
        />
        <button-common :size="SIZE_T" :clr="CLR_M" @click="onCheckEmail"
          >중복확인</button-common
        >
        <span
          class="nick-check"
          v-if="
            isCheckEmail ||
            (checkEmail === authData.id && checkEmail.length !== 0)
          "
          >사용가능한 아이디 입니다.</span
        >
        <span class="nick-error" v-else-if="!isCheckEmail && !isFirst"
          >이미 등록된 아이디 입니다. 다른 아이디로 설정해주세요.</span
        >
        <span class="nick-error" v-else>아이디 중복확인이 필요합니다.</span>
      </div>
      <!-- 비밀번호 설정 -->
      <div class="info-content-wrap">
        <span class="content-category">비밀번호 설정</span>
        <input-text
          style="width: 240px"
          v-model="authData.pass"
        />
      </div>
    </div>
    <!-- 운영권한 설정 -->
    <div class="info-container">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">운영권한 설정</span>
        </div>
      </div>
      <div class="info-check-content-wrap">
        <input-check
          style="margin-top: 10px; margin-left: 16px"
          v-model="checkAll"
          @input="onAllCheck"
          >전체선택</input-check
        >
        <div class="check-wrap">
          <!-- 파트너관리 -->
          <div
            class="check-wrap-item"
            v-for="first in authList"
            :key="first.title"
          >
            <div class="check-wrap-header">
              <input-check :value="first.checked" @input="onFirstCheck(first)"
                ><span class="header-title">{{
                  first.title
                }}</span></input-check
              >
            </div>
            <div
              class="check-wrap-content"
              v-for="second in first.subList"
              :key="second.title"
            >
              <input-check
                :value="second.checked"
                @input="onSecondCheck(first, second)"
                >{{ second.title }}</input-check
              >
              <input-check
                v-for="third in second.subList"
                :key="third.title"
                :value="third.checked"
                :disabled="true"
                style="margin-top: 16px"
                ><span class="disabled-title">{{
                  third.title
                }}</span></input-check
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 아래 버튼 -->
    <div class="fixed d-flex align-items-center flex-end under-finish">
      <!-- <div class="warning fr-12 mr-32">
        <slot />
      </div> -->
      <div>
        <button-common
          :size="SIZE_T"
          :clr="CLR_W"
          wp-cls="mr-8"
          @click="onCancel"
          >취소</button-common
        >
        <button-common
          :size="SIZE_T"
          :clr="CLR_W"
          wp-cls="mr-8"
          style="color: #f1431d"
          v-if="isEdit"
          @click="onRemove"
          >계정삭제</button-common
        >
        <button-common
          :size="SIZE_T"
          :clr="CLR_M"
          :disabled="!checkValid"
          @click="onAdd"
          v-if="!isEdit"
          >추가</button-common
        >
        <button-common
          :size="SIZE_T"
          :clr="CLR_M"
          :disabled="!checkValid"
          @click="onEdit"
          v-if="isEdit"
          >수정</button-common
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SystemAuthAdd",
  mixins: [],
  components: {},

  /*** data ***/
  data() {
    return {
      authData: {
        memo: "",
        id: "",
        pass: "",
        auth_system: "",
        auth_comp: "",
        auth_member: "",
        auth_pay: "",
        auth_alarm: "",
        auth_notice: "",
      },
      authList: [
        // 파트너관리
        {
          checked: false,
          title: "파트너관리",
          subList: [
            {
              checked: false,
              title: "파트너 신청 내역",
              value: "PAT_A",
              subList: [
                { checked: false, title: "신청 현황" },
                { checked: false, title: "신청승인 현황" },
              ],
            },
            {
              checked: false,
              title: "파트너 등록 내역",
              value: "PAT_R",
              subList: [{ checked: false, title: "파트너 등록 내역" }],
            },
            {
              checked: false,
              title: "계약 내역",
              value: "PAT_C",
              subList: [
                { checked: false, title: "계약신청 현황" },
                { checked: false, title: "계약 현황" },
              ],
            },
          ],
        },
        // 멤버관리
        {
          checked: false,
          title: "멤버관리",
          subList: [
            {
              checked: false,
              title: "멤버 가입 정보",
              value: "MEM_A",
              subList: [
                { checked: false, title: "가입중 멤버" },
                { checked: false, title: "가입해지 멤버" },
              ],
            },
            {
              checked: false,
              title: "멤버 결제 내역",
              value: "MEM_B",
              subList: [
                { checked: false, title: "결제 현황" },
                { checked: false, title: "취소 현황" },
              ],
            },
          ],
        },
        // 정산관리
        {
          checked: false,
          title: "정산관리",
          subList: [
            {
              checked: false,
              title: "전체 결제 내역",
              value: "ALL_P",
              subList: [
                { checked: false, title: "결제 현황" },
                { checked: false, title: "매출 현황" },
                { checked: false, title: "결제 오류 현황" },
              ],
            },
            {
              checked: false,
              title: "파트너 정산 내역",
              value: "CAL_P",
              subList: [
                { checked: false, title: "정산 내역" },
                { checked: false, title: "정산 요청 내역" },
              ],
            },
          ],
        },
        // 알림관리
        {
          checked: false,
          title: "알림관리",
          subList: [
            {
              checked: false,
              title: "파트너 알림",
              value: "ALA_P",
              subList: [
                { checked: false, title: "알림 현황" },
                { checked: false, title: "즉시 알림" },
                { checked: false, title: "자동 알림" },
              ],
            },
            {
              checked: false,
              title: "멤버 알림",
              value: "ALA_M",
              subList: [
                { checked: false, title: "알림 현황" },
                { checked: false, title: "즉시 알림" },
                { checked: false, title: "자동 알림" },
              ],
            },
          ],
        },
        // 공지사항
        {
          checked: false,
          title: "공지사항",
          subList: [
            {
              checked: false,
              title: "파트너 공지사항",
              value: "NOT_P",
              subList: [],
            },
            {
              checked: false,
              title: "멤버 공지사항",
              value: "NOT_M",
              subList: [],
            },
          ],
        },
        // 시스템관리
        {
          checked: false,
          title: "시스템관리",
          subList: [
            {
              checked: false,
              title: "운영 계정 관리",
              value: "MAN_A",
              subList: [
                { checked: false, title: "계정 현황" },
                { checked: false, title: "계정 추가" },
              ],
            },
            {
              checked: false,
              title: "파트너 카테고리 관리",
              value: "CAT_P",
              subList: [{ checked: false, title: "카테고리 설정" }],
            },
            {
              checked: false,
              title: "앱 시스템 관리",
              value: "APP_S",
              subList: [{ checked: false, title: "안내글 설정" }],
            },
            {
              checked: false,
              title: "계약내용 설정",
              value: "CON_C",
              subList: [
                { checked: false, title: "결제 동의 설정" },
                { checked: false, title: "파트너 계약내용 설정" },
              ],
            },
            {
              checked: false,
              title: "회원가입약관 설정",
              value: "MEM_T",
              subList: [
                { checked: false, title: "소비자 약관 설정" },
                { checked: false, title: "파트너 약관 설정" },
              ],
            },
          ],
        },
      ],
      checkEmail: "",
      isCheckEmail: false,
      isFirst: true,
      checkAll: false,
      isEdit: false,
    };
  },

  /*** created ***/
  created() {},

  /*** mounted ***/
  async mounted() {
    this.isEdit = this.$route.params.id ? true : false;
    if (this.$route.params.id) {
      await this.getAccountData();
    }
  },

  /*** computed ***/
  computed: {
    checkValid() {
      return (
        this.isCheckEmail ||
        (this.checkEmail === this.authData.id && this.checkEmail.length !== 0)
      );
    },
  },

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    /** @ 계정 정보 조회 ***/
    async getAccountData() {
      const rs = await this.$axios({
        url: "/admin/account/one",
        params: { esntlId: this.$route.params.id },
      });
      if (rs.result === "SUC") {
        if (rs.resultData) {
          this.authData = rs.resultData;
          this.checkEmail = rs.resultData.id;
          this.setAuthList();
        }
      } else {
        console.log("계정 정보 조회 실패");
      }
    },

    setAuthList() {
      const authList = [
        ...this.authData.auth_comp.split(","),
        ...this.authData.auth_member.split(","),
        ...this.authData.auth_pay.split(","),
        ...this.authData.auth_alarm.split(","),
        ...this.authData.auth_notice.split(","),
        ...this.authData.auth_system.split(","),
      ];
      this.authList.forEach((first) => {
        first.subList.forEach((second) => {
          second.checked = authList.includes(second.value);
          if (second.checked) {
            second.subList.forEach((third) => {
              third.checked = second.checked;
            });
          }
        });
      });
      // 첫번째 체크
      this.authList.forEach((first) => {
        first.checked =
          first.subList.filter((item) => item.checked).length ===
          first.subList.length;
      });
      // 전체 체크
      this.checkAll =
        this.authList.filter((item) => item.checked).length ===
        this.authList.length;
    },

    async onCheckEmail() {
      const rs = await this.$axios({
        url: "/admin/account/check",
        params: { id: this.authData.id },
      });
      this.isFirst = false;
      this.isCheckEmail = rs.result === "SUC";
    },

    onAllCheck() {
      this.authList.forEach((first) => {
        first.checked = this.checkAll;
        first.subList.forEach((second) => {
          second.checked = this.checkAll;
          second.subList.forEach((third) => {
            third.checked = this.checkAll;
          });
        });
      });
    },

    onFirstCheck(first) {
      first.checked = !first.checked;
      first.subList.forEach((second) => {
        second.checked = first.checked;
        second.subList.forEach((third) => {
          third.checked = first.checked;
        });
      });
      this.checkAll =
        this.authList.filter((item) => item.checked).length ===
        this.authList.length;
    },

    onSecondCheck(first, second) {
      second.checked = !second.checked;
      second.subList.forEach((third) => {
        third.checked = second.checked;
      });
      first.checked =
        first.subList.filter((item) => item.checked).length ===
        first.subList.length;
      this.checkAll =
        this.authList.filter((item) => item.checked).length ===
        this.authList.length;
    },

    async onRemove() {
      await this.$openConfirm(
        {
          bodyTx: "계정을 삭제 하시겠습니까?",
          cbPrmy: this.deleteAuth,
          btnPrmyNm: "삭제",
          btnScndNm: "취소",
          data: {},
        },
        this
      );
    },

    async onAdd() {
      this.authData.auth_comp = this.setSendAuthData(this.authList[0]);
      this.authData.auth_member = this.setSendAuthData(this.authList[1]);
      this.authData.auth_pay = this.setSendAuthData(this.authList[2]);
      this.authData.auth_alarm = this.setSendAuthData(this.authList[3]);
      this.authData.auth_notice = this.setSendAuthData(this.authList[4]);
      this.authData.auth_system = this.setSendAuthData(this.authList[5]);
      const rs = await this.$axios({
        url: "/admin/account/add",
        params: this.authData,
      });
      if (rs.result === "SUC") {
        this.onCancel();
      } else {
        console.log("계정 정보 추가 실패");
      }
    },

    async onEdit() {
      this.authData.esntlId = this.$route.params.id;
      this.authData.auth_comp = this.setSendAuthData(this.authList[0]);
      this.authData.auth_member = this.setSendAuthData(this.authList[1]);
      this.authData.auth_pay = this.setSendAuthData(this.authList[2]);
      this.authData.auth_alarm = this.setSendAuthData(this.authList[3]);
      this.authData.auth_notice = this.setSendAuthData(this.authList[4]);
      this.authData.auth_system = this.setSendAuthData(this.authList[5]);
      const rs = await this.$axios({
        url: "/admin/account/edit",
        params: this.authData,
      });
      if (rs.result === "SUC") {
        this.onCancel();
      } else {
        console.log("계정 정보 수정 실패");
      }
    },

    setSendAuthData(first) {
      const authList = [];
      first.subList.forEach((second) => {
        if (second.checked) {
          authList.push(second.value);
        }
      });
      return authList.join(",");
    },

    async deleteAuth() {
      const rs = await this.$axios({
        url: "/admin/account/del",
        params: { esntlId: this.$route.params.id },
      });
      if (rs.result === "SUC") {
        this.onCancel();
      } else {
        console.log("계정 정보 삭제 실패");
      }
    },

    onCancel() {
      this.$router.push({
        path: `/system/auth/list`,
        meta: { id: "16-1-1" },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
  margin-top: 10px;
}
.info-header-wrap {
  width: 100%;
  padding: 16px 32px;
  border-bottom: 1px solid #d8dae6;
}
.header-category {
  color: #515466;
  font-size: 16px;
  font-weight: 700;
  margin-right: 32px;
}
.info-content-wrap {
  padding: 16px 0;
  display: flex;
  align-items: center;
}
.content-category {
  color: #828699;
  padding-left: 32px;
  width: 200px;
}
.nick-error {
  font-size: 12px;
  font-weight: 400;
  color: #f1431d;
  margin-left: 16px;
}
.nick-check {
  color: #2ec8b5;
  font-size: 12px;
  font-weight: 700;
  margin-left: 16px;
}
.info-check-content-wrap {
  padding: 16px;
}
.check-wrap {
  width: 100%;
  margin-top: 26px;
  display: flex;
  flex-wrap: wrap;
}
.check-wrap-item {
  width: 25%;
}
.check-wrap-header {
  width: 100%;
  background-color: #828699;
  padding: 14px 16px;
}
.check-wrap-content {
  padding: 20px 16px;
}
.header-title {
  color: #fff !important;
}
.disabled-title {
  color: #9a9eb2;
}
</style>
